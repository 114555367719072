











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import CommonStep from "@/views/Authscreen/Subsidy/Steps/CommonStep.vue";
import StepTwo from "@/views/Authscreen/Subsidy/Steps/ImprovedSeedDistributor/StepTwo.vue";
import {
  ImprovedSeedDistributer,
  ImprovedSeedProducer,
  SubsidyDocument
} from "@/store/models/user";
import { CropVarietyStore, DemandSupplyStore, MetaStore, UserStore } from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { SubsidyForm } from "@/store/models/crop";
import { getNestedFormData, removeFilesForPatch } from "@/utils/helpers";
import { Base } from "@/store/API/api";
import { roleChecker } from "@/utils/jwt";
import { Bank } from "@/store/models/meta";

@Component({
  components: {
    CommonStep,
    StepTwo
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("improved")} ${this.$t("seed")} ${this.$t("distributor")} ${this.$t(
        "registration"
      )}`,
      meta: [
        {
          name: "description",
          content: "Improved Seed Producer Registration",
          vmid: "registration"
        }
      ]
    };
  }
})
export default class extends Vue {
  checkedTerms: Boolean = false;
  active: number = 0;
  disableConfirm: Boolean = true;
  message: string = "";
  total_steps: number = 1;
  subsidyDocument: SubsidyDocument = {} as SubsidyDocument;
  crop: number = 0;

  improvedUser: ImprovedSeedProducer = {} as ImprovedSeedProducer;

  get subsidySchedule() {
    return DemandSupplyStore.SubsidySchedule;
  }

  get nextText() {
    if (this.active == this.total_steps - 1) {
      return this.$t("register");
    }

    return this.$t("next");
  }

  otherDocs: File[] = [] as File[];
  otherDocs1: File[] = [] as File[];
  otherDocs2: File[] = [] as File[];
  otherDocs3: File[] = [] as File[];
  preinformation: File[] = [] as File[];
  firstFieldInspectionReport: File[] = [] as File[];
  lastFieldInspectionReport: File[] = [] as File[];
  tagdetail: File[] = [] as File[];
  seedanalyicslab: File[] = [] as File[];
  breederpurchasebill: File[] = [] as File[];
  salesseedbill: File[] = [] as File[];
  lotQuantityReport: File[] = [] as File[];

  chequeBookCover: File[] = [] as File[];

  handleAfterUploadChequeBookCover(file: any) {
    this.chequeBookCover = [];
    this.chequeBookCover.push(file.raw);
  }

  // private get varieties() {
  //   let varieties = CropVarietyStore.CropVarieties;
  //   if (varieties !== undefined && this.subsidyDocument.crop_type !== undefined) {
  //     varieties = varieties.filter(x => x.croptypeid == this.subsidyDocument.crop_type);
  //   } else {
  //     varieties = [];
  //   }
  //   return varieties;
  // }

  get isSourceSeedProducer() {
    if (roleChecker(["source_seed_producer"])) {
      return true;
    }
    return false;
  }

  get currentSubsidyBatch() {
    return DemandSupplyStore.CurrentSubsidyBatch;
  }

  get currentSubsidyDocument() {
    const document = DemandSupplyStore.CurrentSubsidyDocument;
    console.log("current subsidy -> ", document);
    this.subsidyDocument = JSON.parse(JSON.stringify(DemandSupplyStore.CurrentSubsidyDocument));
    return document;
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  get banklist() {
    return MetaStore.BankList;
  }

  async created() {
    if (this.$route.query.crop) {
      this.crop = Number(this.$route.query.crop);
      DemandSupplyStore.getSubsidyCrops({ lang: this.$i18n.locale });
      DemandSupplyStore.getAllSupplies({ lang: this.$i18n.locale, crop: this.crop });
      await DemandSupplyStore.getCurrentSubsidyBatch({ cropId: this.crop }).catch(() => {
        this.$notify.error("Could not get current subsidy batch!!! Please refresh the page");
      });
      await DemandSupplyStore.getSubsidyDocument({ subsidyBatchId: this.currentSubsidyBatch.id });
    }
    await DemandSupplyStore.getSubsidy();
    CropVarietyStore.getAllCropVarietiesList(this.$i18n.locale);
    MetaStore.getAllFiscalYear();
    MetaStore.getAllBankList();
    await UserStore.getUser();
    console.log("User:", UserStore.UserDetail);
    this.improvedUser = UserStore.UserDetail as ImprovedSeedProducer;

    // this.subsidyDocument.preinformation = []
    // this.currentSubsidyDocument.preinformation = []
  }

  get crops() {
    const subsidy = DemandSupplyStore.SubsidyCrops;
    const crops = subsidy.map(x => {
      return { id: x.crop_type, name: x.crop_name };
    });
    return crops;
  }

  get cropName() {
    const crop = this.crops.find(x => x.id == this.crop);
    if (crop) {
      return crop.name;
    }
    return "";
  }

  get titleText() {
    let textBefore: string = "";

    if (roleChecker(["improved_seed_producer"])) {
      textBefore = this.$t("subsidyDocumentBefore").toString();
    } else {
      textBefore = this.$t("subsidyDocumentBeforeSource").toString();
    }
    let textAfter = this.$t("subsidyDocumentAfter");
    let text = "";
    if (this.$i18n.locale == "ne") {
      text = ` ${textBefore}${this.cropName}${textAfter}`;
    } else {
      text = `${text} of ${this.cropName}`;
    }
    return text;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get user() {
    console.log("USER", UserStore.User);
    return UserStore.User;
  }

  get fiscalYears() {
    console.log("F! ", MetaStore.FiscalYear);
    return MetaStore.FiscalYear;
  }

  get subsidyList() {
    return DemandSupplyStore.Subsidy;
  }

  async uploadPreinfo(file: any) {
    let error = false;
    this.disableConfirm = true;
    this.preinformation = [];
    this.preinformation.push(file.raw);
    this.subsidyDocument.preinformation.push(file.raw);

    let documentFormData = new FormData();
    // documentFormData.append("subsidy_batch", String(this.currentSubsidyBatch.id));
    for (let file of this.subsidyDocument.preinformation) {
      documentFormData.append("preinformation", file);
    }

    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      console.log(err);
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }
    this.subsidyDocument.preinformation = file.raw;
    this.disableConfirm = false;
  }

  removePreinfo() {
    this.preinformation = [];
    this.subsidyDocument.preinformation = [];
  }

  async removeDocument(event: any, str: any) {
    let error = false;
    const subsidyDocumentFormData = new FormData();

    if (str.toLowerCase() == "preinfo".toLowerCase()) {
      this.preinformation = [];
      (this as any).subsidyDocument.preinformation = [];

      subsidyDocumentFormData.append("preinformation", String([]));

      await DemandSupplyStore.removeSubsidyDocument({
        id: this.currentSubsidyDocument.id,
        subsidyDocument: subsidyDocumentFormData
      }).catch(err => {
        error = true;
      });
    } else if (str.toLowerCase() == "firstFieldInspectionReport".toLowerCase()) {
      this.firstFieldInspectionReport = [];
      this.subsidyDocument.first_field_inspection_report = [];

      subsidyDocumentFormData.append("first_field_inspection_report", String([]));

      await DemandSupplyStore.removeSubsidyDocument({
        id: this.currentSubsidyDocument.id,
        subsidyDocument: subsidyDocumentFormData
      }).catch(err => {
        error = true;
      });
    } else if (str.toLowerCase() == "tagDetail".toLowerCase()) {
      this.tagdetail = [];
      this.subsidyDocument.tagdetail = [];
      subsidyDocumentFormData.append("tagdetail", String([]));

      await DemandSupplyStore.removeSubsidyDocument({
        id: this.currentSubsidyDocument.id,
        subsidyDocument: subsidyDocumentFormData
      }).catch(err => {
        error = true;
      });
    } else if (str.toLowerCase() == "lastFieldInspectionReport".toLowerCase()) {
      this.lastFieldInspectionReport = [];
      this.subsidyDocument.last_field_inspection_report = [];

      subsidyDocumentFormData.append("last_field_inspection_report", String([]));

      await DemandSupplyStore.removeSubsidyDocument({
        id: this.currentSubsidyDocument.id,
        subsidyDocument: subsidyDocumentFormData
      }).catch(err => {
        error = true;
      });
    } else if (str.toLowerCase() == "lotQuantityReport".toLowerCase()) {
      this.lotQuantityReport = [];
      this.subsidyDocument.lot_quantity_report = [];

      subsidyDocumentFormData.append("lot_quantity_report", String([]));

      await DemandSupplyStore.removeSubsidyDocument({
        id: this.currentSubsidyDocument.id,
        subsidyDocument: subsidyDocumentFormData
      }).catch(err => {
        error = true;
      });
    } else if (str.toLowerCase() == "seedAnalysisLabReport".toLowerCase()) {
      this.seedanalyicslab = [];
      this.subsidyDocument.seedanalyicslab = [];

      subsidyDocumentFormData.append("seedanalyicslab", String([]));

      await DemandSupplyStore.removeSubsidyDocument({
        id: this.currentSubsidyDocument.id,
        subsidyDocument: subsidyDocumentFormData
      }).catch(err => {
        error = true;
      });
    } else if (str.toLowerCase() == "breederBill".toLowerCase()) {
      this.breederpurchasebill = [];
      this.subsidyDocument.breederpurchasebill = [];

      subsidyDocumentFormData.append("breederpurchasebill", String([]));

      await DemandSupplyStore.removeSubsidyDocument({
        id: this.currentSubsidyDocument.id,
        subsidyDocument: subsidyDocumentFormData
      }).catch(err => {
        error = true;
      });
    } else if (str.toLowerCase() == "breederBill".toLowerCase()) {
      this.breederpurchasebill = [];
      this.subsidyDocument.breederpurchasebill = [];

      subsidyDocumentFormData.append("breederpurchasebill", String([]));

      await DemandSupplyStore.removeSubsidyDocument({
        id: this.currentSubsidyDocument.id,
        subsidyDocument: subsidyDocumentFormData
      }).catch(err => {
        error = true;
      });
    } else if (str.toLowerCase() == "salesBill".toLowerCase()) {
      this.salesseedbill = [];
      this.subsidyDocument.salesseedbill = [];

      subsidyDocumentFormData.append("salesseedbill", String([]));

      await DemandSupplyStore.removeSubsidyDocument({
        id: this.currentSubsidyDocument.id,
        subsidyDocument: subsidyDocumentFormData
      }).catch(err => {
        error = true;
      });
    } else if (str.toLowerCase() == "otherDocs".toLowerCase()) {
      this.otherDocs = [];
      this.subsidyDocument.otherdocuments = [];

      subsidyDocumentFormData.append("otherdocuments", String([]));

      await DemandSupplyStore.removeSubsidyDocument({
        id: this.currentSubsidyDocument.id,
        subsidyDocument: subsidyDocumentFormData
      }).catch(err => {
        error = true;
      });
    }

    if (!error) {
      this.$notify.success("Sucessfully removed Documents.");
    } else {
      this.$notify.error("Couldn't remove documents");
    }
  }

  async uploadFirstFieldInspectionReport(file: any) {
    this.disableConfirm = true;
    let error = false;
    this.firstFieldInspectionReport = [];
    this.firstFieldInspectionReport.push(file.raw);

    let documentFormData = new FormData();
    for (let file of this.firstFieldInspectionReport) {
      documentFormData.append("first_field_inspection_report", file);
    }
    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }
    this.subsidyDocument.first_field_inspection_report = file.raw;
    this.disableConfirm = false;
  }

  removeFirstFieldInspectionReport(file: any) {
    this.firstFieldInspectionReport = [];
    this.subsidyDocument.first_field_inspection_report = [];
  }

  async uploadLastFieldInspectionReport(file: any) {
    this.disableConfirm = true;
    let error = false;
    this.lastFieldInspectionReport = [];
    this.lastFieldInspectionReport.push(file.raw);

    let documentFormData = new FormData();
    for (let file of this.lastFieldInspectionReport) {
      documentFormData.append("last_field_inspection_report", file);
    }
    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }

    this.subsidyDocument.last_field_inspection_report = file.raw;
    this.disableConfirm = false;
  }

  removeLastFieldInspectionReport(file: any) {
    this.lastFieldInspectionReport = [];
    this.subsidyDocument.last_field_inspection_report = [];
  }

  async uploadLotQuantityReport(file: any) {
    this.disableConfirm = true;
    let error = false;
    this.lotQuantityReport = [];
    this.lotQuantityReport.push(file.raw);

    let documentFormData = new FormData();
    for (let file of this.lotQuantityReport) {
      documentFormData.append("lot_quantity_report", file);
    }
    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }

    this.subsidyDocument.lot_quantity_report = file.raw;
    this.disableConfirm = false;
  }

  removeLotQuantityReport(file: any) {
    this.lotQuantityReport = [];
    this.subsidyDocument.lot_quantity_report = [];
  }

  async uploadSalesBill(file: any) {
    this.disableConfirm = true;
    let error = false;
    this.salesseedbill = [];
    this.salesseedbill.push(file.raw);

    let documentFormData = new FormData();
    for (let file of this.salesseedbill) {
      documentFormData.append("salesseedbill", file);
    }
    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }

    this.subsidyDocument.salesseedbill = file.raw;
    this.disableConfirm = false;
  }
  removeSalesBill(file: any) {
    this.salesseedbill = [];
    this.subsidyDocument.salesseedbill = [];
  }

  async uploadSeedAnalytics(file: any) {
    this.disableConfirm = true;
    let error = false;
    this.seedanalyicslab = [];
    this.seedanalyicslab.push(file.raw);

    let documentFormData = new FormData();
    for (let file of this.seedanalyicslab) {
      documentFormData.append("seedanalyicslab", file);
    }
    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }

    this.subsidyDocument.seedanalyicslab = file.raw;
    this.disableConfirm = false;
  }

  removeSeedAnalytics(file: any) {
    this.seedanalyicslab = [];
    this.subsidyDocument.seedanalyicslab = [];
  }

  async uploadBreederBill(file: any) {
    this.disableConfirm = true;
    let error = false;

    this.breederpurchasebill = [];
    this.breederpurchasebill.push(file.raw);

    let documentFormData = new FormData();
    for (let file of this.breederpurchasebill) {
      documentFormData.append("breederpurchasebill", file);
    }
    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }

    this.subsidyDocument.breederpurchasebill = file.raw;
    this.disableConfirm = false;
  }

  removeBreederBill(file: any) {
    this.breederpurchasebill = [];
    this.subsidyDocument.breederpurchasebill = [];
  }

  async uploadTagDetail(file: any) {
    this.disableConfirm = true;
    let error = false;
    this.tagdetail = [];
    this.tagdetail.push(file.raw);

    let documentFormData = new FormData();
    for (let file of this.tagdetail) {
      documentFormData.append("tagdetail", file);
    }
    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }

    this.subsidyDocument.tagdetail = file.raw;
    this.disableConfirm = false;
  }
  removeTagDetail(file: any) {
    this.tagdetail = [];
    this.subsidyDocument.tagdetail = [];
  }

  async uploadOtherDocs(file: any) {
    this.disableConfirm = true;
    let error = false;
    this.otherDocs = [];
    this.otherDocs.push(file.raw);

    let documentFormData = new FormData();
    for (let file of this.otherDocs) {
      documentFormData.append("otherdocuments", file);
    }
    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }

    this.subsidyDocument.otherdocuments = file.raw;
    this.disableConfirm = false;
  }

  removeOtherDocs(file: any) {
    this.otherDocs = [];
    this.subsidyDocument.otherdocuments = [];
  }

  async uploadOtherDocs1(file: any) {
    this.disableConfirm = true;
    let error = false;
    this.otherDocs1 = [];
    this.otherDocs1.push(file.raw);

    let documentFormData = new FormData();
    for (let file of this.otherDocs1) {
      documentFormData.append("otherdocuments_1", file);
    }
    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }

    this.subsidyDocument.otherdocuments_1 = file.raw;
    this.disableConfirm = false;
  }

  removeOtherDocs1(file: any) {
    this.otherDocs1 = [];
    this.subsidyDocument.otherdocuments_1 = [];
  }

  async uploadOtherDocs2(file: any) {
    this.disableConfirm = true;
    let error = false;
    this.otherDocs2 = [];
    this.otherDocs2.push(file.raw);

    let documentFormData = new FormData();
    for (let file of this.otherDocs2) {
      documentFormData.append("otherdocuments-2", file);
    }
    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }

    this.subsidyDocument.otherdocuments_2 = file.raw;
    this.disableConfirm = false;
  }

  removeOtherDocs2(file: any) {
    this.otherDocs2 = [];
    this.subsidyDocument.otherdocuments_2 = [];
  }

  async uploadOtherDocs3(file: any) {
    this.disableConfirm = true;
    let error = false;
    this.otherDocs3 = [];
    this.otherDocs3.push(file.raw);

    let documentFormData = new FormData();
    for (let file of this.otherDocs3) {
      documentFormData.append("otherdocuments3", file);
    }
    await DemandSupplyStore.patchSubsidyDocument({
      subsidyDocument: documentFormData,
      id: this.currentSubsidyDocument.id
    }).catch(err => {
      error = true;
    });

    if (!error) {
      this.$notify.success(`Successfully Added Document`);
    } else {
      this.$notify.error(`An Error occurred while adding documents`);
    }

    this.subsidyDocument.otherdocuments_3 = file.raw;
    this.disableConfirm = false;
  }

  removeOtherDocs3(file: any) {
    this.otherDocs3 = [];
    this.subsidyDocument.otherdocuments_3 = [];
  }

  handlePreview(file: any) {
    if (file.url) {
      const url = file.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    }
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  downloadFile(url: string, name: string = "") {
    if (url != "") {
      const link = document.createElement("a");
      link.href = Base + url;
      if (name != "") {
        link.setAttribute("download", name);
      }
      document.body.appendChild(link);
      link.click();
    }
  }

  get dataList() {
    return DemandSupplyStore.Supplies;
  }
  async submit() {
    let error = false;
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      this.$confirm(
        this.$t("confirmSubsidySubmitMessage").toString(),
        this.$t("warning").toString(),
        {
          confirmButtonText: this.$t("submit").toString(),
          confirmButtonClass: "el-button--primary",
          cancelButtonText: this.$t("cancel").toString(),
          showClose: false,
          type: "warning"
        }
      )
        .then(async () => {
          let subsidyDocFormData = new FormData();
          let company = new FormData();

          subsidyDocFormData.append("subsidy_batch", String(this.currentSubsidyBatch.id));

          // for (let file of this.preinformation) {
          //   subsidyDocFormData.append("preinformation", file);
          // }
          // for (let file of this.firstFieldInspectionReport) {
          //   subsidyDocFormData.append("first_field_inspection_report", file);
          // }
          // for (let file of this.lastFieldInspectionReport) {
          //   subsidyDocFormData.append("last_field_inspection_report", file);
          // }
          // for (let file of this.tagdetail) {
          //   subsidyDocFormData.append("tagdetail", file);
          // }

          // for (let file of this.seedanalyicslab) {
          //   subsidyDocFormData.append("seedanalyicslab", file);
          // }

          // for (let file of this.salesseedbill) {
          //   subsidyDocFormData.append("salesseedbill", file);
          // }

          // for (let file of this.otherDocs) {
          //   subsidyDocFormData.append("otherdocuments", file);
          // }

          // for (let file of this.otherDocs1) {
          //   subsidyDocFormData.append("otherdocuments_1", file);
          // }

          // for (let file of this.otherDocs2) {
          //   subsidyDocFormData.append("otherdocuments_2", file);
          // }

          // for (let file of this.otherDocs3) {
          //   subsidyDocFormData.append("otherdocuments_3", file);
          // }

          // for (let file of this.breederpurchasebill) {
          //   subsidyDocFormData.append("breederpurchasebill", file);
          // }

          let subsidyDocs = await DemandSupplyStore.updateSubsidyDocuments({
            id: this.currentSubsidyDocument.id,
            subsidyDocument: subsidyDocFormData
          })
            .then(async () => {
              await DemandSupplyStore.submitSubsidyBatch({ id: this.currentSubsidyBatch.id }).catch(
                () => {
                  error = true;
                }
              );
            })
            .catch(err => {
              error = true;
            });

          let banks: Bank[] = [];
          for (let category of this.banklist) {
            banks = [...banks, ...category.banks];
          }
          console.log(banks);
          const bank = banks.find(x => String(x.id) == this.improvedUser.bank_name);
          console.log(this.improvedUser.bank_name);
          console.log(bank);
          if (bank) {
            company.append("bank_name", `${bank.id}`);
          }
          company.append("bank_branch", this.improvedUser.bank_branch);
          company.append("bank_acc_name", this.improvedUser.bank_acc_name);
          company.append("account_number", this.improvedUser.account_number);

          for (let file of this.chequeBookCover) {
            company.append("cheque_book_cover", file);
          }

          const companyId = this.user.id;

          await UserStore.patchImprovedSeedProducer({
            id: companyId,
            company: company
          }).catch(err => {
            error = true;
            console.log(err);
          });

          if (!error) {
            this.$notify.success(`${this.$tc("documentSuccess")}`);
            this.$router.push({
              name: "CompanyMenu"
            });
            this.tagdetail = [];
            this.otherDocs = [];
            this.otherDocs1 = [];
            this.otherDocs2 = [];
            this.otherDocs3 = [];
            this.preinformation = [];
            this.seedanalyicslab = [];
            this.firstFieldInspectionReport = [];
            this.lastFieldInspectionReport = [];
            this.salesseedbill = [];
            this.breederpurchasebill = [];
          } else {
            this.$notify.error(`Error`);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("cancelled").toString()
          });
        });
    }
  }

  toggleSubmitButton() {
    let checkedTerms = this.checkedTerms;

    if (checkedTerms) {
      this.disableConfirm = false;
    } else {
      this.disableConfirm = true;
    }
  }

  back() {
    if (this.active > 0) {
      this.active--;
    }
  }
}
