var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Registrationinit myCard"},[_c('h3',{staticClass:"bolder heading textCenter"},[_vm._v(_vm._s(_vm.titleText))]),_c('p',{staticClass:"bolder noteFont heading textCenter"},[_vm._v(_vm._s(_vm.$t("documentDownload")))]),(!_vm.subsidySchedule.can_edit && _vm.subsidySchedule.can_update_document)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.active == -1),expression:"active == -1"}],staticClass:"flex flexColumn alignCenter",attrs:{"element-loading-text":"Registering ..."}},[_c('el-form',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"row"},[(!_vm.roleChecker(['improved_seed_producer']))?_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
            'is-error': _vm.vErrors.has(_vm.$t('tagDetail')),
            'is-required': _vm.checkIfRequired(_vm.$t('tagDetail')),
            fullWidth: _vm.mobileScreen
          },attrs:{"label":((_vm.$t('tagDetail')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"on-change":_vm.uploadTagDetail,"on-remove":function (event) { return _vm.removeDocument(event, 'tagDetail'); },"on-preview":_vm.handlePreview,"list-type":"text","action":"","auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.tagdetail}},[_c('el-button',{staticClass:"download-form-button",attrs:{"size":"small","type":"success","native-type":"button"},on:{"click":function($event){return _vm.downloadFile(
                    '/static/download_documents/ट्यागको विवरण.docx',
                    'ट्यागको विवरण.docx'
                  )}}},[_vm._v(" "+_vm._s(_vm.$t("downloadForm"))+" ")]),_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: _vm.subsidyDocument.tagdetail && _vm.subsidyDocument.tagdetail.length < 1,
                  mimes: 'application/pdf'
                }),expression:"{\n                  required: subsidyDocument.tagdetail && subsidyDocument.tagdetail.length < 1,\n                  mimes: 'application/pdf'\n                }"}],attrs:{"slot":"trigger","name":_vm.$t('tagDetail'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.tagdetail),callback:function ($$v) {_vm.tagdetail=$$v},expression:"tagdetail"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1),(_vm.vErrors.has(_vm.$t('tagDetail')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("tagDetail")))+" ")]):_vm._e()],1)]):_vm._e(),(_vm.roleChecker(['source_seed_producer']))?_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
            'is-error': _vm.vErrors.has(_vm.$t('lotQuantityReport')),
            'is-required': _vm.checkIfRequired(_vm.$t('lotQuantityReport')),
            fullWidth: _vm.mobileScreen
          },attrs:{"label":((_vm.$t('lotQuantityReport')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","on-change":_vm.uploadLotQuantityReport,"on-remove":function (event) { return _vm.removeDocument(event, 'lotQuantityReport'); },"on-preview":_vm.handlePreview,"action":"","auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.lot_quantity_report}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required:
                    _vm.subsidyDocument.lot_quantity_report &&
                    _vm.subsidyDocument.lot_quantity_report.length < 1,
                  mimes: 'application/pdf'
                }),expression:"{\n                  required:\n                    subsidyDocument.lot_quantity_report &&\n                    subsidyDocument.lot_quantity_report.length < 1,\n                  mimes: 'application/pdf'\n                }"}],attrs:{"slot":"trigger","name":_vm.$t('lotQuantityReport'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.lotQuantityReport),callback:function ($$v) {_vm.lotQuantityReport=$$v},expression:"lotQuantityReport"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('lotQuantityReport')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("lotQuantityReport")))+" ")]):_vm._e(),_c('br'),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("note"))+": ")]),_vm._v(" "+_vm._s(_vm.$t("lotQuantityReportNote"))+" ")])]):_vm._e(),(_vm.roleChecker(['source_seed_producer']))?_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
            'is-error': _vm.vErrors.has(_vm.$t('seedAnalysisLabReport')),
            'is-required': _vm.checkIfRequired(_vm.$t('seedAnalysisLabReport')),
            fullWidth: _vm.mobileScreen
          },attrs:{"label":((_vm.$t('seedAnalysisLabReport')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","on-change":_vm.uploadSeedAnalytics,"on-remove":function (event) { return _vm.removeDocument(event, 'seedAnalysisLabReport'); },"on-preview":_vm.handlePreview,"action":"","auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.seedanalyicslab}},[_c('el-button',{staticClass:"download-form-button",attrs:{"size":"small","type":"success","native-type":"button"},on:{"click":function($event){return _vm.downloadFile(
                    '/static/download_documents/बीउको सम्पूर्ण विश्लेषण प्रतिवेदन सारांशमा.docx',
                    'बीउको सम्पूर्ण विश्लेषण प्रतिवेदन सारांशमा.docx'
                  )}}},[_vm._v(" "+_vm._s(_vm.$t("downloadForm"))+" ")]),_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required:
                    _vm.subsidyDocument.seedanalyicslab && _vm.subsidyDocument.seedanalyicslab.length < 1,
                  mimes: 'application/pdf'
                }),expression:"{\n                  required:\n                    subsidyDocument.seedanalyicslab && subsidyDocument.seedanalyicslab.length < 1,\n                  mimes: 'application/pdf'\n                }"}],attrs:{"slot":"trigger","name":_vm.$t('seedAnalysisLabReport'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.seedanalyicslab),callback:function ($$v) {_vm.seedanalyicslab=$$v},expression:"seedanalyicslab"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('seedAnalysisLabReport')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("seedAnalysisLabReport")))+" ")]):_vm._e()]):_vm._e()],1),_c('el-form-item',{class:{
          'is-error': _vm.vErrors.has('check'),
          'is-required': _vm.checkIfRequired('check'),
          fullWidth: _vm.mobileScreen
        },staticStyle:{"text-align":"center"}},[_c('el-checkbox',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"check"},on:{"change":_vm.toggleSubmitButton},model:{value:(_vm.checkedTerms),callback:function ($$v) {_vm.checkedTerms=$$v},expression:"checkedTerms"}},[_c('span',{staticStyle:{"font-size":"1.2em"}},[_vm._v(" "+_vm._s(_vm.$t("acceptTerms"))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vErrors.has('check')),expression:"vErrors.has('check')"}],staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first("check"))+" ")])],1)],1),_c('div',[_c('router-link',{staticStyle:{"margin-right":"10px"},attrs:{"to":{ name: 'AddSupply', query: { crop: _vm.crop } }}},[_c('el-button',{attrs:{"type":"warning","icon":"el-icon-back"}},[_vm._v(_vm._s(_vm.$t("back")))])],1),_c('el-button',{staticClass:"nxt",attrs:{"disabled":_vm.disableConfirm,"type":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("submit")))])],1)],1):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.active == -1),expression:"active == -1"}],staticClass:"flex flexColumn alignCenter",attrs:{"element-loading-text":"Registering ..."}},[_c('el-form',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"row"},[(!_vm.roleChecker(['improved_seed_producer']))?_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
            'is-error': _vm.vErrors.has(_vm.$t('preInformationForm')),
            'is-required': _vm.checkIfRequired(_vm.$t('preInformationForm')),
            fullWidth: _vm.mobileScreen
          },attrs:{"label":((_vm.$t('preInformationForm')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{class:{ fullWidth: _vm.mobileScreen },attrs:{"limit":1,"list-type":"text","on-change":_vm.uploadPreinfo,"on-remove":function (event) { return _vm.removeDocument(event, 'preInfo'); },"on-preview":_vm.handlePreview,"action":"","auto-upload":true,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.preinformation}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({
                  mimes: 'application/pdf'
                }),expression:"{\n                  mimes: 'application/pdf'\n                }"}],attrs:{"slot":"trigger","name":_vm.$t('preInformationForm'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.preinformation),callback:function ($$v) {_vm.preinformation=$$v},expression:"preinformation"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('preInformationForm')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("preInformationForm")))+" ")]):_vm._e()]):_vm._e(),(!_vm.roleChecker(['improved_seed_producer']))?_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
            'is-error': _vm.vErrors.has(_vm.$t('firstFieldInspectionReport')),
            'is-required': _vm.checkIfRequired(_vm.$t('firstFieldInspectionReport')),
            fullWidth: _vm.mobileScreen
          },attrs:{"label":((_vm.$t('firstFieldInspectionReport')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","on-change":_vm.uploadFirstFieldInspectionReport,"on-remove":function (event) { return _vm.removeDocument(event, 'firstFieldInspectionReport'); },"on-preview":_vm.handlePreview,"action":"","auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.first_field_inspection_report}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({}),expression:"{}"}],attrs:{"slot":"trigger","name":_vm.$t('firstFieldInspectionReport'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.firstFieldInspectionReport),callback:function ($$v) {_vm.firstFieldInspectionReport=$$v},expression:"firstFieldInspectionReport"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('firstFieldInspectionReport')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("firstFieldInspectionReport")))+" ")]):_vm._e()]):_vm._e(),(!_vm.roleChecker(['improved_seed_producer']))?_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
            'is-error': _vm.vErrors.has(_vm.$t('lastFieldInspectionReport')),
            'is-required': _vm.checkIfRequired(_vm.$t('lastFieldInspectionReport')),
            fullWidth: _vm.mobileScreen
          },attrs:{"label":((_vm.$t('lastFieldInspectionReport')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","on-change":_vm.uploadLastFieldInspectionReport,"on-remove":function (event) { return _vm.removeDocument(event, 'lastFieldInspectionReport'); },"on-preview":_vm.handlePreview,"action":"","auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.last_field_inspection_report}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required:
                    _vm.subsidyDocument.last_field_inspection_report &&
                    _vm.subsidyDocument.last_field_inspection_report.length < 1,
                  mimes: 'application/pdf'
                }),expression:"{\n                  required:\n                    subsidyDocument.last_field_inspection_report &&\n                    subsidyDocument.last_field_inspection_report.length < 1,\n                  mimes: 'application/pdf'\n                }"}],attrs:{"slot":"trigger","name":_vm.$t('lastFieldInspectionReport'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.lastFieldInspectionReport),callback:function ($$v) {_vm.lastFieldInspectionReport=$$v},expression:"lastFieldInspectionReport"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('lastFieldInspectionReport')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("lastFieldInspectionReport")))+" ")]):_vm._e()]):_vm._e(),(_vm.roleChecker(['improved_seed_producer']))?_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
            'is-error': _vm.vErrors.has(_vm.$t('lotQuantityReport')),
            'is-required': _vm.checkIfRequired(_vm.$t('lotQuantityReport')),
            fullWidth: _vm.mobileScreen
          },attrs:{"label":((_vm.$t('lotQuantityReport')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","on-change":_vm.uploadLotQuantityReport,"on-remove":function (event) { return _vm.removeDocument(event, 'lotQuantityReport'); },"on-preview":_vm.handlePreview,"action":"","auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.lot_quantity_report}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required:
                    _vm.subsidyDocument.lot_quantity_report &&
                    _vm.subsidyDocument.lot_quantity_report.length < 1,
                  mimes: 'application/pdf'
                }),expression:"{\n                  required:\n                    subsidyDocument.lot_quantity_report &&\n                    subsidyDocument.lot_quantity_report.length < 1,\n                  mimes: 'application/pdf'\n                }"}],attrs:{"slot":"trigger","name":_vm.$t('lotQuantityReport'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.lotQuantityReport),callback:function ($$v) {_vm.lotQuantityReport=$$v},expression:"lotQuantityReport"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('lotQuantityReport')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("lotQuantityReport")))+" ")]):_vm._e(),_c('br'),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("note"))+": ")]),_vm._v(" "+_vm._s(_vm.$t("lotQuantityReportNote"))+" ")])]):_vm._e(),(_vm.roleChecker(['improved_seed_producer']))?_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
            'is-error': _vm.vErrors.has(_vm.$t('seedAnalysisLabReport')),
            'is-required': _vm.checkIfRequired(_vm.$t('seedAnalysisLabReport')),
            fullWidth: _vm.mobileScreen
          },attrs:{"label":((_vm.$t('seedAnalysisLabReport')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","on-change":_vm.uploadSeedAnalytics,"on-remove":function (event) { return _vm.removeDocument(event, 'seedAnalysisLabReport'); },"on-preview":_vm.handlePreview,"action":"","auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.seedanalyicslab}},[_c('el-button',{staticClass:"download-form-button",attrs:{"size":"small","type":"success","native-type":"button"},on:{"click":function($event){return _vm.downloadFile(
                    '/static/download_documents/बीउको सम्पूर्ण विश्लेषण प्रतिवेदन सारांशमा.docx',
                    'बीउको सम्पूर्ण विश्लेषण प्रतिवेदन सारांशमा.docx'
                  )}}},[_vm._v(" "+_vm._s(_vm.$t("downloadForm"))+" ")]),_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required:
                    _vm.subsidyDocument.seedanalyicslab && _vm.subsidyDocument.seedanalyicslab.length < 1,
                  mimes: 'application/pdf'
                }),expression:"{\n                  required:\n                    subsidyDocument.seedanalyicslab && subsidyDocument.seedanalyicslab.length < 1,\n                  mimes: 'application/pdf'\n                }"}],attrs:{"slot":"trigger","name":_vm.$t('seedAnalysisLabReport'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.seedanalyicslab),callback:function ($$v) {_vm.seedanalyicslab=$$v},expression:"seedanalyicslab"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('seedAnalysisLabReport')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("seedAnalysisLabReport")))+" ")]):_vm._e()]):_vm._e(),_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
            'is-error': _vm.vErrors.has(_vm.$t('breederBill')),
            'is-required': _vm.checkIfRequired(_vm.$t('breederBill')),
            fullWidth: _vm.mobileScreen
          },attrs:{"label":((_vm.$t('breederBill')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","on-change":_vm.uploadBreederBill,"on-remove":function (event) { return _vm.removeDocument(event, 'breederBill'); },"on-preview":_vm.handlePreview,"action":"","auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.breederpurchasebill}},[_c('el-button',{staticClass:"download-form-button",attrs:{"size":"small","type":"success","native-type":"button"},on:{"click":function($event){_vm.downloadFile(
                    '/static/download_documents/बीउ (प्रजनन् तथा मूल) खरिद बील.docx',
                    'बीउ (प्रजनन् तथा मूल) खरिद बील.docx'
                  )}}},[_vm._v(" "+_vm._s(_vm.$t("downloadForm"))+" ")]),_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required:
                    _vm.subsidyDocument.breederpurchasebill &&
                    _vm.subsidyDocument.breederpurchasebill.length < 1,
                  mimes: 'application/pdf'
                }),expression:"{\n                  required:\n                    subsidyDocument.breederpurchasebill &&\n                    subsidyDocument.breederpurchasebill.length < 1,\n                  mimes: 'application/pdf'\n                }"}],attrs:{"slot":"trigger","name":_vm.$t('breederBill'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.breederpurchasebill),callback:function ($$v) {_vm.breederpurchasebill=$$v},expression:"breederpurchasebill"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('breederBill')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("breederBill")))+" ")]):_vm._e()]),_c('div',{staticClass:"docs_info"},[_c('h5',[_vm._v("Other Documents")]),_c('div',{staticClass:"row"},[_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
                'is-error': _vm.vErrors.has(_vm.$t('otherDocs')),
                'is-required': _vm.checkIfRequired(_vm.$t('otherDocs')),
                fullWidth: _vm.mobileScreen
              },attrs:{"label":((_vm.$t('otherDocs')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","action":"","on-change":_vm.uploadOtherDocs,"on-remove":function (event) { return _vm.removeDocument(event, 'otherDocs'); },"on-preview":_vm.handlePreview,"auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.otherdocuments},model:{value:(_vm.otherDocs),callback:function ($$v) {_vm.otherDocs=$$v},expression:"otherDocs"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('mimes:application/pdf'),expression:"'mimes:application/pdf'"}],attrs:{"slot":"trigger","name":_vm.$t('otherDocs'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.otherDocs),callback:function ($$v) {_vm.otherDocs=$$v},expression:"otherDocs"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('otherDocs')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("otherDocs")))+" ")]):_vm._e()]),_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
                'is-error': _vm.vErrors.has(_vm.$t('otherDocs')),
                'is-required': _vm.checkIfRequired(_vm.$t('otherDocs')),
                fullWidth: _vm.mobileScreen
              },attrs:{"label":((_vm.$t('otherDocs')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","action":"","on-change":_vm.uploadOtherDocs1,"on-remove":function (event) { return _vm.removeDocument(event, 'otherDocs'); },"on-preview":_vm.handlePreview,"auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.otherdocuments_1},model:{value:(_vm.otherDocs1),callback:function ($$v) {_vm.otherDocs1=$$v},expression:"otherDocs1"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('mimes:application/pdf'),expression:"'mimes:application/pdf'"}],attrs:{"slot":"trigger","name":_vm.$t('otherDocs'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.otherDocs1),callback:function ($$v) {_vm.otherDocs1=$$v},expression:"otherDocs1"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('otherDocs')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("otherDocs")))+" ")]):_vm._e()]),_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
                'is-error': _vm.vErrors.has(_vm.$t('otherDocs')),
                'is-required': _vm.checkIfRequired(_vm.$t('otherDocs')),
                fullWidth: _vm.mobileScreen
              },attrs:{"label":((_vm.$t('otherDocs')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","action":"","on-change":_vm.uploadOtherDocs2,"on-remove":function (event) { return _vm.removeDocument(event, 'otherDocs'); },"on-preview":_vm.handlePreview,"auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.otherdocuments_2},model:{value:(_vm.otherDocs2),callback:function ($$v) {_vm.otherDocs2=$$v},expression:"otherDocs2"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('mimes:application/pdf'),expression:"'mimes:application/pdf'"}],attrs:{"slot":"trigger","name":_vm.$t('otherDocs'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.otherDocs2),callback:function ($$v) {_vm.otherDocs2=$$v},expression:"otherDocs2"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('otherDocs')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("otherDocs")))+" ")]):_vm._e()]),_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
                'is-error': _vm.vErrors.has(_vm.$t('otherDocs')),
                'is-required': _vm.checkIfRequired(_vm.$t('otherDocs')),
                fullWidth: _vm.mobileScreen
              },attrs:{"label":((_vm.$t('otherDocs')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","action":"","on-change":_vm.uploadOtherDocs3,"on-remove":function (event) { return _vm.removeDocument(event, 'otherDocs'); },"on-preview":_vm.handlePreview,"auto-upload":false,"accept":"application/pdf","file-list":_vm.currentSubsidyDocument.otherdocuments_3},model:{value:(_vm.otherDocs3),callback:function ($$v) {_vm.otherDocs3=$$v},expression:"otherDocs3"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('mimes:application/pdf'),expression:"'mimes:application/pdf'"}],attrs:{"slot":"trigger","name":_vm.$t('otherDocs'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.otherDocs3),callback:function ($$v) {_vm.otherDocs3=$$v},expression:"otherDocs3"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('otherDocs')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("otherDocs")))+" ")]):_vm._e()])],1)]),_c('div',{staticClass:"contact_info"},[_c('h5',[_vm._v(_vm._s(_vm.$t("bankInfo")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
                  'is-error': _vm.vErrors.has(_vm.$t('bankName')),
                  'is-required': _vm.checkIfRequired(_vm.$t('bankName'))
                },attrs:{"label":_vm.$t('bankName')}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"fullWidth",attrs:{"name":_vm.$t('bankName'),"filterable":""},model:{value:(_vm.improvedUser.bank_name),callback:function ($$v) {_vm.$set(_vm.improvedUser, "bank_name", $$v)},expression:"improvedUser.bank_name"}},_vm._l((_vm.banklist),function(group){return _c('el-option-group',{key:group.category,attrs:{"label":group.category}},_vm._l((group.banks),function(bank){return _c('el-option',{key:bank.id,attrs:{"value":bank.id,"label":bank.name}})}),1)}),1),(_vm.vErrors.has(_vm.$t('bankName')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("bankName")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
                  'is-error': _vm.vErrors.has(_vm.$t('bankBranch')),
                  'is-required': _vm.checkIfRequired(_vm.$t('bankBranch'))
                },attrs:{"label":_vm.$t('bankBranch')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$t('bankBranch')},model:{value:(_vm.improvedUser.bank_branch),callback:function ($$v) {_vm.$set(_vm.improvedUser, "bank_branch", $$v)},expression:"improvedUser.bank_branch"}}),(_vm.vErrors.has(_vm.$t('bankBranch')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("bankBranch")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
                  'is-error': _vm.vErrors.has(_vm.$t('bankAccName')),
                  'is-required': _vm.checkIfRequired(_vm.$t('bankAccName'))
                },attrs:{"label":_vm.$t('bankAccName')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$t('bankAccName')},model:{value:(_vm.improvedUser.bank_acc_name),callback:function ($$v) {_vm.$set(_vm.improvedUser, "bank_acc_name", $$v)},expression:"improvedUser.bank_acc_name"}}),(_vm.vErrors.has(_vm.$t('bankAccName')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("bankAccName")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
                  'is-error': _vm.vErrors.has(_vm.$t('accountNumber')),
                  'is-required': _vm.checkIfRequired(_vm.$t('accountNumber'))
                },attrs:{"label":_vm.$t('accountNumber')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$t('accountNumber')},model:{value:(_vm.improvedUser.account_number),callback:function ($$v) {_vm.$set(_vm.improvedUser, "account_number", $$v)},expression:"improvedUser.account_number"}}),(_vm.vErrors.has(_vm.$t('accountNumber')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("accountNumber")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('el-form-item',{class:{
                  'is-error': _vm.vErrors.has(_vm.$t('chequeBookCover')),
                  'is-required': _vm.checkIfRequired(_vm.$t('chequeBookCover'))
                },attrs:{"label":((_vm.$t('chequeBookCoverWithAccNo')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.improvedUser.cheque_book_cover,"on-change":_vm.handleAfterUploadChequeBookCover,"limit":1,"list-type":"text","action":"","auto-upload":false,"accept":"application/pdf"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({
                        required:
                          _vm.improvedUser.cheque_book_cover &&
                          _vm.improvedUser.cheque_book_cover.length < 1,
                        mimes: 'application/pdf'
                      }),expression:"{\n                        required:\n                          improvedUser.cheque_book_cover &&\n                          improvedUser.cheque_book_cover.length < 1,\n                        mimes: 'application/pdf'\n                      }"}],attrs:{"slot":"trigger","name":_vm.$t('chequeBookCover'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.chequeBookCover),callback:function ($$v) {_vm.chequeBookCover=$$v},expression:"chequeBookCover"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('chequeBookCover')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("chequeBookCover")))+" ")]):_vm._e()])],1)])])],1),_c('el-form-item',{class:{
          'is-error': _vm.vErrors.has('check'),
          'is-required': _vm.checkIfRequired('check'),
          fullWidth: _vm.mobileScreen
        },staticStyle:{"text-align":"center"}},[_c('el-checkbox',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"check"},on:{"change":_vm.toggleSubmitButton},model:{value:(_vm.checkedTerms),callback:function ($$v) {_vm.checkedTerms=$$v},expression:"checkedTerms"}},[_c('span',{staticStyle:{"font-size":"1.2em"}},[_vm._v(" "+_vm._s(_vm.$t("acceptTerms"))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vErrors.has('check')),expression:"vErrors.has('check')"}],staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first("check"))+" ")])],1)],1),_c('div',[_c('router-link',{staticStyle:{"margin-right":"10px"},attrs:{"to":{ name: 'AddSupply', query: { crop: _vm.crop } }}},[_c('el-button',{attrs:{"type":"warning","icon":"el-icon-back"}},[_vm._v(_vm._s(_vm.$t("back")))])],1),_c('el-button',{staticClass:"nxt",attrs:{"disabled":_vm.disableConfirm,"type":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("submit")))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }