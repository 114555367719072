















































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { CropName } from "@/store/models/crop";
import { Supply } from "@/store/models/DemandAndSupply";
import {
  CropStore,
  DemandSupplyStore,
  CropVarietyStore,
  UserStore,
  JWTStore,
  MetaStore
} from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";
import { MetaInfo } from "vue-meta";
import { eventHub } from "@/utils/eventHub";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";
import { roleChecker } from "@/utils/jwt";
import { localeData } from "moment";

@Component({
  components: {
    DynamicField
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$tc("addSupply", 1)}`,
      meta: [
        { name: "keyword", content: "Sqcc supply list" },
        {
          name: "description",
          content: "List of Supplies of seeds",
          vmid: "supplyList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  // disableConfirm: Boolean = false;
  searchInput: string = "";
  Id: string = "" as any;
  pageNum: number = 1;
  supplyCrop: CropName = {} as CropName;
  supply: Supply = {} as Supply;
  language: string = "";
  varietyName: string = "";
  seedType: number = 1;
  qty: string = "";
  clearButton: boolean = false;
  loading: boolean = false;
  adding: boolean = false;
  multipleSelection: any[] = [];
  crop: number = 0;
  selectedIndex: number = -1;

  supplyDetail: Supply = {} as Supply;
  uploadBillDialogVisible: boolean = false;

  proofOfSupply: File[] = [] as File[];

  areaType: string = "hec";
  finalApprovedAreaType: string = "hec";

  typeOfAreas: Array<any> = [
    {
      id: "hec",
      name: this.$t("hectareFullForm")
    },
    {
      id: "bg",
      name: this.$t("bigha")
    },
    {
      id: "rop",
      name: this.$t("ropani")
    },

    {
      id: "kth",
      name: this.$t("katha")
    }
  ];

  get dataList() {
    let res: Supply[] = DemandSupplyStore.Supplies;
    // rounding off decimal to two places
    for (let item of res) {
      let seedRes = item.seed_growth_area.toFixed(2);
      let finalRes = !roleChecker(["improved_seed_producer"])
        ? item.final_approved_growth_area.toFixed(2)
        : 0;

      item.seed_growth_area = Number(seedRes);
      item.final_approved_growth_area = Number(finalRes);
    }

    return res;
  }
  get disableConfirm() {
    if (this.dataList.length > 0) {
      return false;
    }
    return true;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  private get user() {
    return UserStore.User;
  }

  // private get crops() {
  //   return CropStore.DSADCropList;
  // }

  get seedTypes() {
    let seedTypes = DemandSupplyStore.SeedTypeList;
    if (this.roleChecker(["source_seed_producer"])) {
      seedTypes = seedTypes.filter(x => [2, 3].includes(x.id));
      if (this.supply.cropvarietyid) {
        let variety = this.varieties.find(x => x.id == this.supply.cropvarietyid);
        if (variety && variety.allowed_seed_types) {
          const allowed_seeds = variety.allowed_seed_types.map(x => x.seed_type);
          seedTypes = seedTypes.filter(x => allowed_seeds.includes(x.id));
        }
      }
    }
    return seedTypes;
  }

  get addSupplyText() {
    let text = this.$tc("addSupply").toString();
    if (!this.subsidySchedule.can_edit && this.subsidySchedule.can_update_document) {
      text = this.$t("uploadSaleBill").toString();
    }
    if (this.$i18n.locale == "ne") {
      text = `${this.cropName}${this.$t("'s")} ${text}`;
    } else {
      text = `${text} of ${this.cropName}`;
    }
    return text;
  }

  get cropName() {
    const crop = this.crops.find(x => x.id == this.crop);
    if (crop) {
      return crop.name;
    }
    return "";
  }

  get maxSoldQuantity() {
    const max =
      this.supply.seedquantity < this.supply.certified_seed_quantity
        ? this.supply.seedquantity
        : this.supply.certified_seed_quantity;
    return max;
  }

  get crops() {
    const subsidy = DemandSupplyStore.SubsidyCrops;
    const crops = subsidy.map(x => {
      return { id: x.crop_type, name: x.crop_name };
    });
    return crops;
  }

  get varieties() {
    const subsidy = DemandSupplyStore.Subsidy;

    if (!this.crop) {
      return [];
    } else {
      let tempVariety = subsidy.filter(x => x.crop_type == this.crop);
      let variety = tempVariety.map(x => {
        return {
          id: x.crop_variety,
          name: x.variety_name,
          allowed_seed_types: x.allowed_seed_types,
          smr: x.smr,
          rate: x.rate
        };
      });
      return variety;
    }
  }

  get currentVariety() {
    const variety = this.varieties.find(x => x.id == this.supply.cropvarietyid);
    return variety;
  }

  get currentSMRVariety() {
    const variety = this.varieties.find(x => x.id == this.supply.cropvarietyid);
    if (variety && variety.allowed_seed_types && this.supply.seedtypeid) {
      if (variety.allowed_seed_types.length == 1) {
        return variety.allowed_seed_types[0];
      }
      const smr = variety.allowed_seed_types.find(x => x.seed_type == this.supply.seedtypeid);
      return smr;
    }
    return {
      smr: 0,
      rate: 0
    };
  }

  get seed_growth_area_ha() {
    let area = this.supply.seed_growth_area;
    if (this.areaType == "bg") {
      area = area / 1.5;
    } else if (this.areaType == "kth") {
      area = area / 30;
    } else if (this.areaType == "rop") {
      area = area / 19.67;
    }
    if (area && typeof area == "number") {
      area = Number(area.toFixed(2));
    }
    return area;
  }

  get final_seed_growth_area_ha() {
    let area = this.supply.final_approved_growth_area;
    if (this.finalApprovedAreaType == "bg") {
      area = area / 1.5;
    } else if (this.finalApprovedAreaType == "kth") {
      area = area / 30;
    } else if (this.finalApprovedAreaType == "rop") {
      area = area / 19.67;
    }
    if (area && typeof area == "number") {
      area = Number(area.toFixed(2));
    }
    return area;
  }

  get maxProducedSeed() {
    let maxProduction = 0;
    if (this.currentSMRVariety && this.seed_growth_area_ha) {
      if (!roleChecker(["improved_seed_producer"])) {
        maxProduction =
          this.currentSMRVariety.smr * this.currentSMRVariety.rate * this.final_seed_growth_area_ha;
      } else {
        maxProduction =
          this.currentSMRVariety.smr * this.currentSMRVariety.rate * this.seed_growth_area_ha;
      }
    }
    if (maxProduction) {
      maxProduction = Number(maxProduction.toFixed(2));
    }
    return maxProduction;
  }

  get currentSubsidyBatch() {
    return DemandSupplyStore.CurrentSubsidyBatch;
  }

  get subsidySchedule() {
    if (roleChecker(["source_seed_producer"])) {
      return DemandSupplyStore.SourceSeedSubsidySchedule;
    } else if (roleChecker(["improved_seed_producer"])) {
      return DemandSupplyStore.ImprovedSeedSubsidySchedule;
    }
    return DemandSupplyStore.SubsidySchedule;
  }

  getSummaries(param: any) {
    const { columns, data } = param;
    const sums: any[] = [];
    columns.forEach((column: any, index: any) => {
      if (index === 0) {
        sums[index] = this.$t("total");
        return;
      }
      const values = data.map((item: any) => Number(item[column.property]));
      if (!values.every((value: any) => isNaN(value))) {
        sums[index] = values.reduce((prev: any, curr: any) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] = this.nepaliUnicodeNumber(this.thousandSeprator(sums[index]));
      } else {
        sums[index] = "";
      }
    });

    return sums;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  get disabledHectareField() {
    if (this.areaType == "") {
      return true;
    }
    return false;
  }

  indexMethod(index: number) {
    return this.nepaliUnicodeNumber(index + 1);
  }

  thousandSeprator(amount: number | string) {
    if (amount == null) {
      return null;
    }
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  // private get varieties() {
  //   let varieties = CropVarietyStore.CropVarieties;
  //   if (!this.supply.dsadgroupid) {
  //     return [];
  //   }
  //   return varieties;
  // }

  loadCropVariety(event: any) {
    this.supply.cropvarietyid = null as any;
    // let cropGroupId = this.crops.filter(x => x.groupid == event)[0].groupid;
    // if (cropGroupId != undefined) {
    //   CropVarietyStore.getAllowedCropVarietiesByCropId({
    //     id: cropGroupId,
    //     lang: this.$i18n.locale
    //   });
    // }
  }

  seedSetter() {
    // if (this.user.role === "source_seed_producer") {
    //   let seedTypeId = this.seedTypes.find(x => x.name.toLowerCase() === "source");
    //   if (seedTypeId) {
    //     this.supply.seedtypeid = seedTypeId.id;
    //   }
    // }
    // else
    if (this.user.role === "improved_seed_producer") {
      let seedTypeId = this.seedTypes.find(x => x.name.toLowerCase() === "improved seed");
      if (seedTypeId) {
        this.supply.seedtypeid = seedTypeId.id;
      }
    }
  }

  handleAfterUploadSupplyProof(file: any) {
    this.proofOfSupply = [];
    this.proofOfSupply.push(file.raw);
    this.supplyDetail.supply_proof = [file.raw];
  }

  handleRemoveSupplyProof() {
    this.proofOfSupply = [];
  }

  convertToHectare(area: number, areaType: string) {
    if (areaType == "bg") {
      area = Number(area) / 1.5;
    } else if (areaType == "kth") {
      area = Number(area) / 30;
    } else if (areaType == "rop") {
      area = Number(area) / 19.67;
    }
    return area;
  }

  convertSeedGrowthArea() {
    this.supply.seed_growth_area = this.convertToHectare(
      this.supply.seed_growth_area,
      this.areaType
    );
    this.areaType = "hec";
  }
  convertFinalSeedGrowthArea() {
    this.supply.final_approved_growth_area = this.convertToHectare(
      this.supply.final_approved_growth_area,
      this.finalApprovedAreaType
    );
    this.finalApprovedAreaType = "hec";
  }

  async add() {
    await this.convertSeedGrowthArea();
    await this.convertFinalSeedGrowthArea();
    let isValid = await this.$validator.validateAll();
    this.seedSetter();
    if (
      (!this.supply.id || this.supply.id == 0) &&
      this.dataList.filter(
        x =>
          x.cropvarietyid == this.supply.cropvarietyid &&
          x.seedtypeid == this.supply.seedtypeid &&
          x.subsidy_batch == this.currentSubsidyBatch.id
      ).length > 0
    ) {
      isValid = false;
      this.$notify.error("Supply already exists");
    }

    if ((!this.supply.id || this.supply.id == 0) && !this.subsidySchedule.can_add) {
      this.$notify.error("Supply deadline already ended. You cannot add supply now.");
      return;
    }

    if (isValid && this.currentSubsidyBatch && this.currentSubsidyBatch.id) {
      // let batchid = this.crops.filter(x => x.groupid == this.supply.dsadgroupid)[0].batchid;
      // if (batchid) {
      //   this.supply.batchid = batchid;
      // }
      let submitSupply = new FormData();
      for (let key in this.supply as any) {
        if ((this.supply as any)[key]) {
          submitSupply.append(key, (this.supply as any)[key]);
        }
      }

      submitSupply.append("subsidy_batch", String(this.currentSubsidyBatch.id));
      // submitSupply.append("lang", this.$i18n.locale);

      for (let file of this.proofOfSupply) {
        submitSupply.append("supply_proof", file);
      }

      this.adding = true;
      let error = false;
      let supply = await DemandSupplyStore.postSupply(submitSupply).catch(res => {
        error = true;
      });
      this.adding = false;
      if (!error) {
        supply = supply as Supply;
        if (!this.clearButton) {
          this.dataList.push(supply);
          this.$notify.success(`${this.$tc("supply")} ${this.$t("added")}`);
        } else {
          this.dataList.splice(this.selectedIndex, 1, supply);
          this.$notify.success(`${this.$tc("supply")} ${this.$t("edited")}`);
        }
        (this.$refs.dataTable as any).toggleAllSelection();
        this.clear();
      }
    } else if (!this.currentSubsidyBatch || !this.currentSubsidyBatch.id) {
      this.$notify.error("Could not get current subsidy batch!!! Please refresh the page");
    }
    // this.toggleConfirm()

    DemandSupplyStore.getAllSupplies({
      lang: this.language,
      crop: this.crop,
      subsidyBatch: this.currentSubsidyBatch.id
    });
  }

  showUploadBillDialog(supply: Supply) {
    this.supplyDetail = JSON.parse(JSON.stringify(supply));
    this.uploadBillDialogVisible = true;
    if (!this.supplyDetail.supply_proof || this.supplyDetail.supply_proof == "") {
      this.supplyDetail.supply_proof = [];
    }
  }

  async uploadSalesBill() {
    const isValid = await this.$validator.validateAll("sales-bill-form");
    console.log("isValid", isValid);
    if (isValid) {
      this.$confirm(
        this.$t("confirmSaleBillUploadMessage").toString(),
        this.$t("warning").toString(),
        {
          confirmButtonText: this.$t("submit").toString(),
          confirmButtonClass: "el-button--primary",
          cancelButtonText: this.$t("cancel").toString(),
          showClose: false,
          type: "warning"
        }
      )
        .then(async () => {
          const supply = new FormData();
          supply.append("soldquantity", String(this.supplyDetail.soldquantity));

          for (let file of this.proofOfSupply) {
            supply.append("supply_proof", file);
          }
          await DemandSupplyStore.uploadSourceSeedSaleBill({
            data: supply,
            id: this.supplyDetail.id
          });
          this.uploadBillDialogVisible = false;
          this.supplyDetail = {} as Supply;
          DemandSupplyStore.getAllSupplies({
            lang: this.language,
            crop: this.crop,
            subsidyBatch: this.currentSubsidyBatch.id
          });
          this.$notify.success(`${this.$t("documentSuccess")}`);
          for (let data of this.multipleSelection) {
            this.dataList.splice(this.dataList.indexOf(data), 1);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("cancelled").toString()
          });
        });
    }
  }

  async confirm() {
    let selection: any[] = [];
    for (let data of this.multipleSelection) {
      selection.push(data.id);
    }
    this.$confirm(this.$t("confirmSupplyMessage").toString(), this.$t("warning").toString(), {
      confirmButtonText: this.$t("submit").toString(),
      confirmButtonClass: "el-button--primary",
      cancelButtonText: this.$t("cancel").toString(),
      showClose: false,
      type: "warning"
    })
      .then(async () => {
        await DemandSupplyStore.confirmSupplies(selection);
        this.$notify.success(`${this.$tc("supply", 2)} ${this.$t("submitted")}`);
        for (let data of this.multipleSelection) {
          this.dataList.splice(this.dataList.indexOf(data), 1);
        }
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  clear() {
    this.supply = {} as Supply;
    this.selectedIndex = -1;
    this.proofOfSupply = [] as File[];
    this.clearButton = false;
    this.areaType = "";
    // (this.$refs.successProofUpload as any).clearFiles();
    this.$validator.reset();
  }

  editForm(id: number) {
    this.selectedIndex = this.dataList.findIndex(x => x.id == id);
    this.supply = Object.assign({}, this.dataList.filter(x => x.id == id)[0]);
    this.areaType = this.typeOfAreas[0].id;
    this.clearButton = true;
  }

  onDeleteClick(index: number, id: number) {
    let cropVariety = this.dataList.filter(x => x.id == id)[0];
    let confirmMessage = `This will permanently delete the supply of crop ${cropVariety.croptypename} of variety ${cropVariety.cropvarietyname} and all related data. Do you want to continue?`;
    if (this.$i18n.locale == "ne") {
      confirmMessage = `यसले स्थायी रूपमा ${cropVariety.croptypename} बालीको ${cropVariety.cropvarietynepname} प्रजातिको आपूर्ति र सबै सम्बन्धित डाटलाई मेटाउनेछ। के तपाईं मेटाउन चाहानुहुन्छ?`;
    }
    this.$confirm(confirmMessage, this.$t("warning").toString(), {
      confirmButtonText: this.$t("delete").toString(),
      confirmButtonClass: "el-button--danger",
      cancelButtonText: this.$t("cancel").toString(),
      showClose: false,
      type: "warning"
    })
      .then(async () => {
        await DemandSupplyStore.deleteSupply(cropVariety.id);
        this.dataList.splice(index, 1);
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  isSupplier() {
    let userRole = this.user.role;
    let roles = ["supplier", "source_seed_producer", "improved_seed_producer"];
    for (let role of roles) {
      if (userRole === role) {
        return true;
      }
    }
    return false;
  }

  onCropVarietyChange() {
    this.seedSetter();
  }

  onSeedTypeChange() {}

  async created() {
    if (JWTStore.loggedIn) {
      await UserStore.getUser();
    }
    if (JWTStore.loggedIn && this.isSupplier()) {
      this.language = this.$i18n.locale;
      if (this.$route.query.page) {
        this.pageNum = Number(this.$route.query.page);
      }
      if (this.$route.query.crop) {
        this.crop = Number(this.$route.query.crop);
        await DemandSupplyStore.getCurrentSubsidyBatch({ cropId: this.crop }).catch(() => {
          this.$notify.error("Could not get current subsidy batch!!! Please refresh the page");
        });
      }

      DemandSupplyStore.getSubsidyCrops({ lang: this.language });
      DemandSupplyStore.getSubsidy({ lang: this.language });
      // CropStore.getDSADCropName({ phase: "supply", lang: this.language });
      // CropVarietyStore.getAllCropVarietiesList(this.$i18n.locale);
      await DemandSupplyStore.getAllSeedType();
      this.seedSetter();
      this.loading = true;

      await DemandSupplyStore.getAllSupplies({
        lang: this.language,
        crop: this.crop,
        subsidyBatch: this.currentSubsidyBatch.id
      });
      this.loading = false;

      // (this.$refs.dataTable as any).toggleAllSelection();
    } else {
      eventHub.$emit("loginClick", "companyUser", "You need to login company user to continue");
    }
  }

  next() {
    this.$router.push({ name: "AddSupplyDocument", query: { crop: String(this.crop) } });
  }

  handlePageChange(val: number) {
    this.pageNum = val;
    CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: val,
      language: this.$i18n.locale
    });

    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { page: String(val) }
        })
        .catch(error => {});
    }
  }
}
